.titlePageNotFound {
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 2rem;
  color: #313336;
}
.custom-primary.btn.btn-secondary {
  background: #fff;
  border-radius: 8px;
  border-color: #fff;
  max-height: 48px;
  padding: 0.5rem 2rem 0.55rem !important;
  height: 48px;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  color: #1b212e;
}
.custom-primary.btn.btn-secondary:hover {
  background: #ffffff00;
  color: #fff;
}
.pageNotFoundMin-height-100per {
  min-height: 100vh;
  padding: 3rem 0;
}
