.footer .rights p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #b3bbd3;
}
.footer .title-div img {
  margin-bottom: 1.7rem;
  width: 110px;
}
p.text-footer {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  text-transform: capitalize;
  /* color: #ffffff; */
  margin-bottom: 2.5rem;
}

.content-footer {
  padding-bottom: 3rem;
}
.footer {
  background: #f4f5f6;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  padding-top: 20rem;
}
.CopyRightFooter.nav span {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #787f84;
  padding: 1rem 1rem 1rem 0;
}
.CopyRightFooter.nav a.nav-link {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #787f84;
  padding: 1rem;
}
.cookies {
  height: 44px;
  background: #fcfdfe;
  border: 1px solid #eeeeee;
  border-radius: 100px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 8px 2rem;
  float: right;
  font-size: 14px;
  line-height: 25px;
}
.cookies span {
  cursor: pointer;
  color: #9b5de5;
  font-weight: 600;
  margin-left: 1rem;
}

@media (max-width: 991.98px) {
  .OurServices-content {
    height: 300px;
  }
  .CopyRightFooter.nav a.nav-link {
    margin: 0 auto;
  }
  .footer .navbar-brand {
    margin: 0 auto;
  }
  .CopyRightFooter.nav span {
    padding: 1rem 0;
    text-align: center;
    margin-bottom: 2rem;
  }
  .cookies {
    float: unset;
    margin: 0 auto;
  }
  .footer {
    padding: 1rem;
  }
  .footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .footer p.text-footer {
    text-align: center;
  }
  .content-footer {
    padding-bottom: 0rem;
  }
  .footer button.navbar-toggler {
    display: none;
  }
}
@media (min-width: 1600px) {
  .footer {
    /* margin-top: -18rem; */
  }
}
@media (max-width: 767.98px) {
  .footer {
    padding: 1rem 1rem 3rem;
  }
  .CopyRightFooter.nav span {
    padding: 1rem 0;
    text-align: center;
  }
  .footer button.navbar-toggler {
    display: none;
  }
  .footer .navbar-brand {
    margin: 0 auto;
  }
  .CopyRightFooter.nav a.nav-link {
    margin: 0 auto;
  }
  .cookies {
    height: auto;
    padding: 8px 1rem;
    text-align: center;
    border-radius: 8px;
  }
}
