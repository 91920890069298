.Expertise-content.col-lg-12 {
  /* background: url(../../../../assets/Mask.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: #9b5de5;
  border-radius: 48px;
  color: #fff;
  padding: 6rem 7rem;
  text-align: center;
  overflow: hidden;
  position: relative;
  height: 430px;
}
.Expertise-content h1 {
  font-weight: 600;
  font-size: 64px;
  line-height: 78px;
  letter-spacing: -0.02em;
  color: #fcfdfe;
  margin-bottom: 2rem;
}
.Expertise-content p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #fcfdfe;
  z-index: 1;
  position: relative;
}
.minHeight-85 {
  min-height: 85vh;
}
@media (max-width: 991px) {
  .Expertise-content.col-lg-12 {
    padding: 4rem 4rem;
    height: auto;
  }
  .minHeight-85 {
    min-height: 60vh;
  }
  .Expertise-content.col-lg-12 {
    border-radius: 20px;
    padding: 4rem;
    height: auto;
  }
  .Expertise-content h1 {
    font-size: 40px;
    line-height: 45px;
  }
  .Expertise-content p {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0;
    letter-spacing: 0.8px;
  }
}
@media (max-width: 768px) {
  .Expertise-content.col-lg-12 {
    border-radius: 20px;
    padding: 3rem 2rem;
    height: auto;
    margin-top: 5rem;
  }
  .Expertise-content h1 {
    font-size: 35px;
    line-height: 40px;
  }
  .Expertise-content p {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0;
    letter-spacing: 0.8px;
  }
}
