.min-height-100 {
  min-height: calc(100vh - 100px);
  padding: 0rem 0rem 0rem;
}
.hero-content h1 {
  font-style: normal;
  margin-bottom: 2rem;
  margin-top: 4rem;
  font-weight: 600;
  font-size: 64px;
  line-height: 70px;
  letter-spacing: -0.02em;
  color: #9b5de5;
}
.hero-content p {
  margin-bottom: 1.5rem;
  letter-spacing: 0.8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #787f84;
}
img.welcome {
  width: 90%;
  float: right;
}
.WelcomToLozrian {
  width: 90%;
  overflow: hidden;
  float: right;
}
img.welcome1 {
  width: 100%;
}
img.welcome6 {
  width: 54%;
  position: absolute;
  right: 22px;
  bottom: 7px;
  animation: vertical-shaking 4s ease-in-out infinite;
}
img.welcome5 {
  position: absolute;
  width: 27%;
  top: 29%;
  left: 20%;
  animation: horizontal-shaking 4s ease-in-out infinite;
}
img.welcome4 {
  position: absolute;
  right: 9%;
  width: 16%;
  top: 10%;
  animation: horizontal-shaking 2s ease-in-out infinite;
}
img.welcome3 {
  position: absolute;
  top: 50%;
  width: 5%;
  left: 20%;
  /* animation: blink 3s infinite; */
  animation: sizechange 2s alternate infinite ease-in;
}
.invalid-feedback {
  color: #ff828e;
}
@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes vertical-shaking {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(-5px);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes sizechange {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.2);
  }
}
@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@media (max-width: 991px) {
  img.welcome {
    width: 100%;
  }
  img.welcome5 {
    left: 10%;
  }
  img.welcome3 {
    left: 8%;
  }
  img.welcome4 {
    right: 15%;
  }
  .hero-content h1 {
    margin-top: 1rem;
    font-size: 40px;
    line-height: 45px;
  }
}
@media (max-width: 768px) {
  img.welcome {
    width: 100%;
  }
  img.welcome5 {
    left: 10%;
  }
  img.welcome3 {
    left: 8%;
  }
  img.welcome4 {
    right: 15%;
  }
  .hero-content h1 {
    margin-top: 1rem;
    font-size: 35px;
    line-height: 40px;
  }
}
