.WhyChooseLozrian-content h1 {
  font-weight: 600;
  font-size: 64px;
  line-height: 78px;
  letter-spacing: -0.02em;
  color: #9b5de5;
  margin-bottom: 2rem;
}
.WhyChooseLozrian-content p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #787f84;
}
img.WhyChooseLozrian {
  width: 90%;
  float: right;
}
.mt-n-3 {
  margin-top: -5rem;
}
@media (min-width: 768px) and (max-width: 991px) {
  .WhyChooseLozrian-content h1 {
    font-size: 40px;
    line-height: 45px;
  }
  .WhyChooseLozrian-content p {
    margin-bottom: 1.5rem;
    letter-spacing: 0.8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #787f84;
  }
  .WhyChooseLozrianContentImg {
    text-align: center;
  }
  img.WhyChooseLozrian {
    width: 75%;
    float: unset;
    margin-bottom: 5rem;
    margin-top: 1rem;
  }
}
@media (max-width: 768px) {
  img.WhyChooseLozrian {
    width: 100%;
  }
  .WhyChooseLozrian-content h1 {
    font-size: 35px;
    line-height: 40px;
  }
  .WhyChooseLozrian-content p {
    letter-spacing: 0.8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
  }
}
