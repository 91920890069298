.GetInTouchTitle h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #9b5de5;
  margin-bottom: 1.5rem;
}
.GetTouch .GetInTouchTitle p {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #313336;
}
.GetInTouchTitle {
  text-align: center;
  margin-bottom: 2rem;
}
.align-items-center.lozrian-world.row {
  background: url(../../../../assets/circleBG.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 90% top;
  background-color: #9b5de5;
  border-radius: 48px;
  color: #fff;
  padding: 2rem 3rem;
  text-align: center;
  overflow: hidden;
  margin-bottom: -16rem;
}
.GetTouch label.form-label {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #fcfdfe;
  text-align: left;
  width: 100%;
}
img.circleBG {
  position: absolute;
  top: -5rem;
  width: 100%;
}
.big-circle {
  animation: rotation2 2s infinite linear;
  position: absolute;
  /* top: 23%; */
  left: -22%;
  /* right: 0%; */
  bottom: -58%;
  margin: auto;
}
.big-circle2 {
  animation: rotation 4s infinite linear;
  position: absolute;
  /* top: 23%; */
  right: -19%;
  /* right: 0%; */
  bottom: -58%;
  margin: auto;
}
img.messageImg {
  width: 82%;
  margin-right: -4rem;
  margin-top: 2rem;
}
img.sendIcon {
  position: absolute;
  right: 15px;
  top: 0;
  height: 76px;
  border-radius: 4px;
  animation: horizontal-shaking 2s ease-in-out infinite;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
img.big-circle {
  width: 54%;
  opacity: 0.8;
}
img.big-circle2 {
  width: 45%;
  opacity: 0.8;
}
img.small-circle {
  height: 143%;
  top: -22%;
  right: -2.3%;
  left: auto;
  opacity: 0.5;
}
.small-circle {
  animation: rotation2 1.5s infinite linear;
  position: absolute;
  top: -8%;
  left: 25%;
}
@keyframes rotation2 {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}
img.center-person {
  position: absolute;
  left: 42%;
  top: 25%;
  border: 10px solid #fff;
  border-radius: 50%;
  box-shadow: -1px 9px 25px 0px #bdbbfe;
  z-index: 1;
}
.global {
  position: absolute;
  height: 100%;
  top: 28px;
}
.bg-border-gradiant.contact-form-bg {
  background: unset;
}
.bg-border-gradiant {
  position: relative;
}
.bg-border-gradiant {
  box-sizing: border-box;
  border-radius: 15px;
  color: #fff;
}
.GetTouch {
}
.GetTouch p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #fff;
  padding-right: 4rem;
}
.GetTouch p a {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #fff;
  padding-right: 4rem;
  text-decoration: unset;
}
.GetTouch h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 1rem;
}
.contact-form-bg .content-what-bullit h3 span {
  background: linear-gradient(270deg, #0ff 0, #48b8e4 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
}
.contact-form-bg h5 {
  color: #9be7ff;
  margin-top: 14px;
  font-size: 14px;
  font-weight: 100;
}
.pl-10,
.px-10 {
  padding-left: 2.5rem !important;
}
.pr-10,
.px-10 {
  padding-right: 2.5rem !important;
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 0.825rem 1.42rem;
  font-size: 1.08rem;
}
.form-group {
  margin-bottom: 1.75rem;
}
.bg-border-gradiant .form-control {
  background-color: #001770;
  border-color: #0b4a8f;
  color: #fff;
}
.bg-border-gradiant.contact-form-bg .form-control.form-control-solid {
  border: 0px solid #969696 !important;
  color: #fff;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
}
.bg-border-gradiant.contact-form-bg .form-control.form-control-solid {
  background: #ab77e9;
  border-radius: 8px;
}
.form-control::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}
.form-control::placeholder {
  color: #fff;
  opacity: 1;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 9px);
  padding: 0.65rem 1rem;
  font-size: 14px;
}
textarea.form-control {
  height: auto;
}
.contact-form-bg .btn.btn-primary {
  background: #fff0;
  border-radius: 8px;
  border-color: #fff;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.contact-form-bg .btn.btn-primary:hover,
.contact-form-bg .btn.btn-primary:active {
  background: #fff0;
  border-radius: 8px;
  border-color: #fff;
}
.bg-border-gradiant.contact-form-bg input:-webkit-autofill,
.bg-border-gradiant.contact-form-bg input:-webkit-autofill:focus,
.bg-border-gradiant.contact-form-bg input:-webkit-autofill:hover,
.bg-border-gradiant.contact-form-bg select:-webkit-autofill,
.bg-border-gradiant.contact-form-bg select:-webkit-autofill:focus,
.bg-border-gradiant.contact-form-bg select:-webkit-autofill:hover,
.bg-border-gradiant.contact-form-bg textarea:-webkit-autofill,
.bg-border-gradiant.contact-form-bg textarea:-webkit-autofill:focus,
.bg-border-gradiant.contact-form-bg textarea:-webkit-autofill:hover {
  border: 1px solid #969696;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0 1000px #873ce2 inset !important;
  transition: background-color 5000s ease-in-out 0s;
}
.invalid-feedback {
  text-transform: capitalize;
}
iframe[title="reCAPTCHA"] .rc-anchor-normal {
  width: 100% !important;
}
.rc-anchor-light.rc-anchor-normal {
  margin-left: auto;
  margin-right: auto;
}
.mt-n-5 {
  margin-top: -5rem;
}
@media (max-width: 991.98px) {
  .mt-n-5 {
    margin-top: 3rem;
  }
  .align-items-center.lozrian-world.row {
    padding: 3rem 2rem;
    margin-bottom: 0rem;
  }
}
@media (max-width: 767.98px) {
  .GetInTouchTitle h2 {
    text-align: left;
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 2rem;
    font-weight: 600;
  }
  .GetTouch .GetInTouchTitle p {
    text-align: left;
    letter-spacing: 0.8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #787f84;
  }
  .align-items-center.lozrian-world.row {
    background-position: 100% top;
    border-radius: 20px;
    background-color: #9b5de5;
    padding: 3rem 1rem;
    margin-bottom: 2rem;
    text-align: left;
  }
  .GetTouch.pt-5,
  .GetTouch .pt-5 {
    padding-top: 1rem !important;
  }
  .GetTouch p {
    padding-right: 0rem;
  }
  .mt-n-5 {
    margin-top: 2rem;
  }
  .custom-margining {
    margin-top: -8rem;
  }
  .rights p {
    text-align: center;
    color: #a3a3a3;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    padding-top: 20px;
  }
  .contact-form-bg .btn.btn-primary {
    border-radius: 30px;
    width: 100%;
    margin-top: 20px;
  }
  .form-group {
    margin-bottom: 0.5rem;
  }
  .Team.GetTouch h1 {
    font-size: 38px;
    line-height: 40px;
    margin-bottom: 2rem;
  }
  iframe[title="reCAPTCHA"] {
    margin-left: 0px;
  }
  body > iframe > body > .rc-anchor-light.rc-anchor-normal {
    border: 1px solid #d3d3d3;
    width: 100% !important;
  }
  body .rc-anchor-light.rc-anchor-normal {
    margin: 0 auto !important;
  }
  .rc-anchor-normal .rc-anchor-pt {
    margin: 2px 0 0 0 !important;
    left: 0;
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .GetInTouchTitle h2 {
    text-align: left;
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 2rem;
    font-weight: 600;
  }
  .GetTouch .GetInTouchTitle p {
    text-align: left;
    letter-spacing: 0.8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #787f84;
  }
  .align-items-center.lozrian-world.row {
    text-align: left;
    border-radius: 20px;
  }
}
