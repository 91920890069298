.ServicesImage {
  margin-left: -1rem;
  width: 54vw;
  margin-bottom: -8rem;
  animation: vertical-shaking 2s ease-in-out infinite;
}
.Services-content h1 {
  font-weight: 600;
  font-size: 64px;
  line-height: 78px;
  align-items: center;
  letter-spacing: -0.02em;
  color: #9b5de5;
  text-align: center;
  width: 100%;
  margin-bottom: 3rem;
}
.OurServices-content {
  height: 400px;
  background: #fcfdfe;
  border: 1px solid #dcdcdc;
  border-radius: 48px;
  padding: 3rem;
  margin-bottom: 2rem;
}
.OurServices-content:hover {
  background: #fff;
  /* box-shadow: 0px 5px 18px 0px #bcbafe; */
  box-shadow: 0px 5px 18px 0px #e8e8e8;
}
img.OurServicesImage {
  width: 64px;
  height: 64px;
  margin-bottom: 1.5rem;
}
.OurServices-content h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #313336;
  margin-bottom: 1rem;
}
.OurServices-content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #787f84;
}
.Services-content {
  /* margin-top: -33rem; */
}
@media (max-width: 991px) {
  .OurServices-content {
    height: auto;
  }
  .Services-content h1 {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 2rem;
  }
}
@media (max-width: 768px) {
  .ServicesImage {
    margin-left: -2rem;
    margin-bottom: -3.5rem;
    margin-top: 5rem;
  }
  .Services-content h1 {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 2rem;
  }
  .OurServices-content {
    height: auto;
    border-radius: 20px;
    padding: 2rem;
  }
  .OurServices-content p {
    letter-spacing: 0.8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0;
  }
  img.OurServicesImage {
    width: 60px;
    height: 60px;
    margin-bottom: 1rem;
  }
}
