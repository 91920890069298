@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
body {
  font-family: "Poppins", sans-serif;
  color: #787f84;
}
.nav-link:focus,
.nav-link:hover {
  /* color: rgb(255 255 255 / 70%); */
}
body {
  background: #fcfdfe !important;
  /* color: #fff; */
  position: relative;
  overflow-x: hidden;
}
.nav-link {
  color: #787f84;
  font-size: 1rem;
  font-weight: 600;
}
.nav-link:focus,
.nav-link:hover {
  color: #9b5de5;
}
.navbar-nav a {
  padding: 0.85rem 1rem !important;
}
nav.navbar {
  height: 100px;
}

button.custom-primary.btn.btn-primary {
  border-color: #9b5de5;
  background: #9b5de5;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.1);
  border-radius: 32px;
}
button.custom-primary.btn.btn-secondary {
  background: #fff;
  border-radius: 8px;
  border-color: #fff;
  max-height: 48px;
  padding: 0.5rem 2rem 0.55rem !important;
  height: 48px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #1b212e;
}
button.custom-primary.btn.btn-secondary:hover {
  background: #fff0;
  border-radius: 8px;
  border-color: #fff;
  color: #fff;
}
.custom-primary.btn.btn-primary {
  border-color: #9b5de5;
  background: #9b5de5;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.1);
  border-radius: 32px;
}
.navbar-nav button.custom-primary.btn.btn-primary {
  max-height: 48px;
  padding: 0.5rem 2rem 0.55rem !important;
  margin-left: 1.7rem;
  height: 48px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}
button.custom-primary.btn.btn-primary {
  max-height: 48px;
  padding: 0.5rem 2rem 0.55rem !important;
  height: 48px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}
button.custom-primary.btn.btn-primary:hover {
  background: #ffffff;
  color: #9b5de5;
}
.custom-primary.btn.btn-primary:hover {
  background: #ffffff;
  color: #9b5de5;
}
button.custom-secondary.btn.btn-outline-secondary {
  max-height: 48px;
  border: 1.5px solid #969696;
  border-radius: 42px;
  padding: 2.5px 1.8rem 5px 4px !important;
  font-weight: bold;
  color: #fff;
}
button.custom-secondary.btn.btn-outline-secondary:hover {
  background-color: #969696;
}
.navbar-nav button.custom-secondary.btn.btn-outline-secondary {
  max-height: 48px;
  border: 3px solid rgb(119 126 144);
  border-radius: 42px;
  padding: 0.1rem 0.6rem 0.15rem 0.18rem !important;
  font-weight: 500;
  color: #fff;
  margin-left: 1rem;
  line-height: 38px;
  display: flex;
}
.mr-3,
.mx-3 {
  margin-right: 0.75rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 0.75rem !important;
}
.wallet-address-button-container .dropdown-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  padding: 0.85rem !important;
}
.dropdown-item:active,
.dropdown-item:focus,
.wallet-address-button-container .dropdown-item:hover {
  background: rgba(156, 68, 245, 0.4);
  border-radius: 7.09371px;
}
.wallet-address-button-container
  .dropdown-item:hover
  i.fas.fa-circle.d-none.d-md-none {
  display: block !important;
  font-size: 7px;
  float: right;
  margin-left: auto;
}
a.dropdown-item img {
  margin-right: 7px;
  height: 22px;
  margin-left: -4px;
}
.wallet-address-button-container .dropdown-menu.show {
  padding: 20.6329px;
  background: rgb(0 0 0 / 54%);
  box-shadow: 0px 21.6329px 86.5317px -64.8987px rgb(31 47 70 / 74%);
  border-radius: 32.4494px 0px 32.4494px 32.4494px;
  backdrop-filter: blur(32px);
  min-width: 220px;
  margin-right: 0px;
  margin-left: auto;
  margin-top: 0px;
  flex-basis: unset;
  flex-grow: unset;
  align-items: center;
}
.dropdown-toggle::after {
  vertical-align: 0.1em;
  margin-top: 18px;
}
img.avatar {
  background: #ff3a8a;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  margin-top: -1px;
  margin-left: -1px;
  object-fit: cover;
}
.addres-info {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 13px;
  font-size: 13px;
}
@media (max-width: 1200px) {
}

.navbar-nav button.btn.auth-btn.navbar-nav {
  padding: 0.85rem 1.5rem !important;
}
.navbar-nav a.btn.auth-btn.navbar-nav {
  padding: 0.85rem 1.5rem !important;
}
.header .header-top {
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 0 solid rgb(255 255 255 / 24%);
}
.btn-side-menu {
  height: calc(1.2em + 1.65rem + 5px);
  padding: 0 0.5rem !important;
  font-size: 1.08rem;
  line-height: 1.5;
  border-radius: 0.42rem;
}
.offcanvas-body .border-top {
  border-top: 1px solid #eee !important;
}
.d-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.navbar-expand-lg .offcanvas .offcanvas-body {
  flex-grow: 0;
  overflow-y: visible;
  padding: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.navbar-expand-lg .offcanvas .offcanvas-body {
  justify-content: right;
}
.navbar-expand-lg .navbar-nav:last-child {
  margin-right: 0;
  margin-left: auto;
}
.menuscroll nav.navbar {
  height: 80px;
}
.menuscroll.header-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 97;
  height: 80px;
  -webkit-animation: header-scroll-animation 0.5s ease 1;
  animation: header-scroll-animation 0.5s ease 1;
  background-color: #f4f5f6;
  -webkit-box-shadow: 0 10px 30px 0 rgb(82 63 105 / 8%);
  box-shadow: 0 10px 30px 0 rgb(82 63 105 / 8%);
}
.top-menue-title.dropdown-toggle.btn.btn-outline-secondary {
  padding: 0.85rem 1rem !important;
  border: 0;
  background: #eee0 !important;
  color: #fff;
}
.DropdownMenu {
  background: rgb(35 35 48 / 40%);
  box-shadow: 3px 5px 35px rgb(0 0 0 / 21%);
  -webkit-backdrop-filter: blur(32px);
  backdrop-filter: blur(32px);
  border-radius: 22px;
  padding: 20px;
  min-width: 220px;
}
.DropdownMenu .navbar-nav {
  display: block;
  margin-bottom: 1px;
}
.DropdownMenu .navbar-nav .nav-link.active {
  background: #0000001c;
  border-radius: 10px;
}
.DropdownMenu .nav-link:hover {
  background: #0000001c;
  border-radius: 10px;
}
.top-menue-title.dropdown-toggle.btn.btn-outline-secondary::after {
  margin-top: 0;
  margin-left: 5px;
}
.menuscroll {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 97;
  height: 80px;
  -webkit-animation: header-scroll-animation 0.5s ease 1;
  animation: header-scroll-animation 0.5s ease 1;
  background-color: #1d1d1dc4;
  -webkit-box-shadow: 0 10px 30px 0 rgb(82 63 105 / 8%);
  box-shadow: 0 10px 30px 0 rgb(82 63 105 / 8%);
}
#walletconnect-qrcode-modal a {
  color: #333;
}
.navbar-nav button.custom-secondary.btn.btn-outline-secondary.px-5 {
  padding: 0.1rem 1.6rem 0.15rem 1.6rem !important;
}
@-webkit-keyframes header-scroll-animation {
  from {
    top: -70px;
  }
  to {
    top: 0;
  }
}
@keyframes header-scroll-animation {
  from {
    top: -70px;
  }
  to {
    top: 0;
  }
}
span.addressUser {
  color: #fff;
  padding: 0rem 0rem 0.85rem !important;
  font-size: 1rem;
  border-bottom: 1px solid #eeeeee40;
  display: block;
}
button.times-cancel i {
  font-size: 20px;
}
button.times-cancel {
  background: #eeeeee30;
  height: 40px;
  width: 40px;
  min-width: 40px;
  position: absolute;
  right: -15px;
  top: -15px;
}
@media (max-width: 767.98px) {
  .header-top {
    zoom: 0.9;
  }
  .wallet-address-button-container .dropdown {
    position: initial;
  }
  .wallet-address-button-container .dropdown-menu.show {
    margin-left: 15%;
    margin-top: -12px;
    flex-basis: unset;
    flex-grow: unset;
    align-items: center;
    min-width: 280px;
  }
  .container-fluid {
    padding: 0 30px;
  }
  .navbar-collapse {
    padding: 28.3932px 21.6329px;
    background: rgb(154 93 228);
    box-shadow: 0px 21.6329px 86.5317px -64.8987px rgb(31 47 70 / 74%);
    border-radius: 32.4494px 0px 32.4494px 32.4494px;
    min-width: 270px;
    margin-right: 0px;
    margin-left: auto;
    margin-top: 0px;
    flex-basis: unset;
    flex-grow: unset;
    align-items: center;
  }
  .navbar-collapse .navbar-nav a {
    padding: 15px !important;
    color: #fff;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link {
    color: #ffffff !important;
  }
  .navbar-collapse a.nav-link.active {
    background-color: #ff3a8a;
    border-radius: 12.1685px;
  }
  button.custom-secondary.btn.btn-outline-secondary {
    border: 0 solid #969696;
    padding: 2.5px 4px 5px 4px !important;
    margin-right: 0;
    margin-left: auto;
  }
  img.avatar {
    margin-right: 0;
    background: #ff3a8a;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin-top: -3px;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link i {
    color: #fff;
    font-size: 10px;
    float: right;
    margin-top: 7px;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link.active i {
    display: block !important;
  }
  span.text-italic {
    color: #2832a1;
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
    letter-spacing: 1.5px;
  }
  .offcanvas-body .text-white {
    color: #3f4254 !important;
  }
  .data-mobile .navi-item.mt-20.progres {
    margin-top: 2.5rem !important;
  }
  .data-mobile a.btn.btn-sm {
    padding: 0.5rem 1.25rem !important;
  }
  .offcanvas-body .navbar-nav {
    zoom: 0.9;
  }
  .nav-SideMenu.navbar-nav {
    padding: 0.85rem 1.5rem !important;
  }
  body .navbar-nav .btn-side-menu {
    width: fit-content;
  }
  .offcanvas {
    max-width: 90%;
  }
  .header-token.container .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    background: #012d68;
  }
  .offcanvas-body {
    padding: 0px;
  }
  a.nav-link.active {
    background-color: #f3f6f9;
    color: #2832a1;
  }
  .header-top a.nav-link.active {
    background: #0000001c;
    border-radius: 10px;
  }
  a.nav-link.active .menu-text {
    color: #2832a1;
  }
  button.custom-secondary.btn.btn-outline-secondary.px-5 {
    padding: 6.5px 10px 6.5px 10px !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .wallet-address-button-container .dropdown {
    position: initial;
  }
  .wallet-address-button-container .dropdown-menu.show {
    margin-left: 55%;
    margin-top: -10px;
    min-width: 280px;
  }
  .titletable {
    display: flex;
    width: max-content;
  }
  .rights p {
    text-align: center !important;
    border-top: 1px solid #eeeeee4f;
    padding-top: 20px;
  }
  .container-fluid .min-height-100per.Team.GetTouch {
    margin-top: 0rem;
    min-height: auto;
    margin-bottom: 5rem;
  }
  .custom-margining {
    margin-top: -24rem;
  }
  .itemslide img {
    margin-bottom: -40px;
    margin-top: -110px;
    width: 80%;
  }
  .itemslide {
    width: 98%;
  }

  .container-fluid {
    padding: 0 30px;
  }
  .navbar-collapse {
    padding: 28.3932px 21.6329px;
    background: rgb(0 0 0 / 54%);
    box-shadow: 0px 21.6329px 86.5317px -64.8987px rgb(31 47 70 / 74%);
    border-radius: 32.4494px 0px 32.4494px 32.4494px;
    min-width: 235px;
    margin-right: 0px;
    margin-left: 55%;
    margin-top: -5px;
  }
  button.custom-secondary.btn.btn-outline-secondary.px-5 {
    padding: 6.5px 10px 6.5px 10px !important;
  }
}
@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav a {
    padding: 15px !important;
    color: #fff;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link {
    color: #ffffff !important;
  }
  .navbar-collapse a.nav-link.active {
    background-color: #ff3a8a;
    border-radius: 12.1685px;
  }
  button.custom-secondary.btn.btn-outline-secondary {
    border: 0 solid #969696;
    padding: 2.5px 4px 5px 4px !important;
    margin-right: 0;
    margin-left: auto;
  }
  img.avatar {
    margin-right: 0;
    background: #ff3a8a;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin-top: -3px;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link i {
    color: #fff;
    font-size: 10px;
    float: right;
    margin-top: 7px;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link.active i {
    display: block !important;
  }
  span.text-italic {
    color: #2832a1;
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
    letter-spacing: 1.5px;
  }
  .offcanvas-body .text-white {
    color: #3f4254 !important;
  }
  .data-mobile .navi-item.mt-20.progres {
    margin-top: 2.5rem !important;
  }
  .data-mobile a.btn.btn-sm {
    padding: 0.5rem 1.25rem !important;
  }
  .offcanvas-body .navbar-nav {
    zoom: 0.9;
  }
  .nav-SideMenu.navbar-nav {
    padding: 0.85rem 1.5rem !important;
  }
  body .navbar-nav .btn-side-menu {
    width: fit-content;
  }
  .offcanvas {
    max-width: 90%;
  }
  .header-token.container .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    background: #012d68;
  }
  .offcanvas-body {
    padding: 0px;
  }
  a.nav-link.active {
    background-color: #f3f6f9;
    color: #2832a1;
  }
  .header-top a.nav-link.active {
    background: #0000001c;
    border-radius: 10px;
  }
  a.nav-link.active .menu-text {
    color: #2832a1;
  }
  button.custom-secondary.btn.btn-outline-secondary {
    border: 0 solid #969696;
    padding: 2.5px 4px 5px 4px !important;
    margin-right: 0;
    margin-left: auto;
  }
  img.avatar {
    margin-right: 0;
    background: #ff3a8a;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin-top: -3px;
  }
  button.custom-secondary.btn.btn-outline-secondary.px-5 {
    padding: 6.5px 10px 6.5px 10px !important;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    height: auto !important;
  }
  .header-top {
    height: 100px;
  }
}
@media (min-width: 992px) and (max-width: 1070px) {
  .header-menu .menu-nav > .menu-item > .menu-link {
    padding: 0.85rem 0.5rem;
    font-size: 1rem;
  }
  img.logo {
    height: 30px !important;
  }
}

@media (min-width: 992px) and (max-width: 1400px) {
  .top-menue-title.dropdown-toggle.btn.btn-outline-secondary,
  body .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0.85rem 0.5rem !important;
    font-size: 1rem;
  }
  .navbar-expand-lg .navbar-nav {
    margin-right: 0.3rem;
  }
  span.beta-span {
    line-height: 30px;
    font-size: 14px;
    margin-left: 10px;
    top: 3px;
  }
}

.navbar-brand {
  width: auto;
  text-align: left;
}

.offcanvas.offcanvas-end {
  left: 0;
  right: unset;
}

.special-link {
  color: #32ffff !important;
  font-size: 14px;
  font-style: italic;
}

.auth-btn,
.mobile-auth-btn {
  color: #fff !important;
  background-color: #fff;
  border: 1px solid #fff;
}

/* .auth-btn:hover {
    background-color: transparent;
} */

.auth-btn span,
.mobile-auth-btn span {
  color: #00122d !important;
}

.auth-btn:focus span,
.auth-btn:active span,
.auth-btn:hover span,
.mobile-auth-btn:focus span,
.mobile-auth-btn:active span,
.mobile-auth-btn:hover span {
  color: #32ffff !important;
}

.auth-btn:focus,
.auth-btn:active,
.auth-btn:hover,
.mobile-auth-btn:focus,
.mobile-auth-btn:active,
.mobile-auth-btn:hover {
  box-shadow: none !important;
  border: 1px #fff solid !important;
  background-color: transparent !important;
}

.navbar-nav a {
  padding: 0.85rem 1.5rem;
}

.navbar-nav button {
  padding: 0.55rem 1rem !important;
}

.secondery-logo {
  padding-top: 1.25rem;
}

.mobile-auth-btn {
  display: none;
}

.mobile-logo {
  display: none;
}
button.navbar-toggler i {
  color: #fff;
  font-size: 35px;
}
.navbar-toggler:focus {
  box-shadow: unset;
}
.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: #f9075e;
}
img.logo-mobile {
  width: 150px;
}
.navbar-toggler {
  padding: 0px;
  font-size: 40px;
  line-height: 1;
  color: rgb(76 79 84);
  background-color: transparent;
  border: 0;
}
div#ContactAcall {
  display: none;
}
@media (max-width: 991.5px) {
  body .navbar-nav .custom-primary.btn.btn-primary {
    margin-left: 0 !important;
  }
  div#ContactAcall {
    display: block;
  }
  /* .navbar-brand {
        display: none !important;
    } */

  .hero-section-box {
    padding: 2.5rem 0 0;
  }
  /* .menuscroll {
    position: relative !important;
  } */

  .navbar {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .menuscroll .navbar {
    padding: 0 30px;
  }
  .navbar-nav .auth-btn {
    display: none;
  }

  .mobile-auth-btn {
    display: block !important;
  }

  .mobile-logo {
    display: block;
  }

  .offcanvas-start {
    width: 21rem !important;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    color: #3f4254 !important;
  }
  .header-top .navbar-expand-lg .navbar-nav .nav-link {
    color: #fff !important;
  }
  .max-h-30px {
    max-height: 30px;
  }
}

.navbar-expand-lg .offcanvas .offcanvas-body {
  justify-content: right;
}
.navbar-nav a.custom-primary.btn.btn-primary {
  max-height: 48px;
  padding: 0.5rem 2rem 0.55rem !important;
  margin-left: 1.7rem;
  height: 48px;
  font-weight: 600;
  font-size: 14px;
  line-height: 29px;
  letter-spacing: 1px;
}
.navbar-nav .custom-primary.btn.btn-primary {
  max-height: 48px;
  padding: 0.5rem 2rem 0.55rem !important;
  margin-left: 1.7rem !important;
  height: 48px;
  font-weight: 600;
  font-size: 14px;
  line-height: 29px;
  letter-spacing: 1px;
}
